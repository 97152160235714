import React, { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate,useRevalidator } from "react-router-dom";
import Help from "../../components/Help";
import { IRentPlaceAnOrderResult, IRentCouponCommand } from "../../interfaces/IRent";
import { IMQTTState, Topics, useMQTTController } from "../../mqtt";
import rentService from "../../services/rentService";
import Modal, { error as modalError } from "../../components/Modal";
import Button from "../../components/Button";
import Keyboard from "../../components/Keyboard";
import Loading from "../../components/Loading";

const Payment = () => {
    const navigate = useNavigate();
    const orderDetail = useLoaderData() as IRentPlaceAnOrderResult;
    const [controller, dispatch] = useMQTTController();
    const [placeOrderResult, setPlaceOrderResult] = useState<boolean>(false);
    const [couponVisible, setCouponVisible] = useState<boolean>(false);
    const keyboard = useRef<any>();
    const maxLength = 30;
    const [rentCouponCommand, setRentCouponCommand] = useState<IRentCouponCommand>({
        coupon: '',
        couponError: ''
    });
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const retaliator = useRevalidator();


    useEffect(() => {
        document.body.className = "main-body";
        window.scrollTo(0, 0);
        setTimeout(() => checkout(), 1000);
    }, []);

    useEffect(() => {
        const { topic, message } = controller as IMQTTState;

        if (topic !== Topics.CLIENT_CHARGE_RESULT) return;

        (dispatch as React.Dispatch<any>)({ type: "clear" });
        const instruct = message ? JSON.parse(message) : {};
        if (!instruct.State || !placeOrderResult) {
            return modalError("Payment Exception", instruct.MessageContent, true, () => checkout());
        }

        navigate("/thank-you", { state: orderDetail });
    }, [controller]);

    const checkout = async () => {
        const result = await rentService.placeOrder(orderDetail?.orderId || 0);
        if (result) {
            setPlaceOrderResult(true);
        }
    };
    const onCouponChange = (coupon: string) => {
        if(coupon.length>maxLength) {
            keyboard.current?.setInput(rentCouponCommand.coupon);
            return;
        };
        rentCouponCommand.coupon = coupon;
        setRentCouponCommand({...rentCouponCommand});
        keyboard.current?.setInput(coupon);
    };

    const addCoupon = async () => {
       
        if(!rentCouponCommand.coupon) {
            rentCouponCommand.couponError = "Please enter the coupon.";
            setRentCouponCommand({...rentCouponCommand});
            return;
        }
        setShowLoading(true);
        const result = await rentService.addCoupon(orderDetail?.orderId || 0, rentCouponCommand.coupon);
        setShowLoading(false);
        if(!result.isSuccess)
        {
            rentCouponCommand.couponError = result.message;
            setRentCouponCommand({...rentCouponCommand});
            return;
        }
        retaliator.revalidate();
        cancelCoupon();
    }

    const cancelCoupon = ()=>{
        setCouponVisible(false);
        clearCoupon();
    }

    const clearCoupon = () => {
        rentCouponCommand.coupon = "";
        rentCouponCommand.couponError = "";
        keyboard.current?.setInput(rentCouponCommand.coupon);
        setRentCouponCommand({...rentCouponCommand});
    };

    return (
        <>
            {/*  start header section  */}
            <header className="bearSprayLogo smallLogo">
                <a href="#">
                    <img src="/assets/images/logo-small.png" alt="bear-spray-logo" className="img-fluid small-logo" />
                </a>
                <nav>
                    <div className="cst-exit-btn">
                        <Button
                            className="btn btn-outline"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Exit
                        </Button>
                    </div>
                    <Help />
                </nav>
            </header>
            {/*  end header section  */}

            {/*  start main content container  */}
            <section>
                <div className="main-content" style={{ marginTop: '311px',height:'1378px' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 content-container">
                                <h1 className="display-5 px-4 text-center mb-5">Complete Your RENTAL</h1>
                                <h2 className="text-center cst-order fs-nunito-medium">Order Details</h2>
                                <div className="cst-table payment-table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Qty</th>
                                                <th>Description</th>
                                                <th>Days</th>
                                                <th className="cst-padding">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{orderDetail?.itemQuantity}</td>
                                                <td>{orderDetail?.itemDescription}</td>
                                                <td>{orderDetail?.rentalDays}</td>
                                                <td className="text-right">
                                                    <span>$</span>
                                                    {orderDetail?.totalAmount?.toFixed(2)}
                                                </td>
                                            </tr>
                                            {
                                                orderDetail?.couponApplied&& (
                                                    <tr>
                                                        <td>&nbsp; </td>
                                                        <td colSpan={2} style={{textAlign:"right"}}>
                                                            {orderDetail?.couponApplied}
                                                        </td>
                                                        <td className="text-right">
                                                            <span>$</span>
                                                            {orderDetail?.discountAmount?.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            <tr className="border-none">
                                                <td>&nbsp; </td>
                                                <td>&nbsp; </td>
                                                <td>Tax</td>
                                                <td className="text-right">
                                                    <span>$</span>
                                                    {orderDetail?.tax?.toFixed(2)}
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td colSpan={2} style={{textAlign:"right"}}>
                                                    {
                                                        !orderDetail?.couponApplied&& (<Button type="button" className="btn btn-danger cst-btn2 mt-0 mb-0"
                                                                                            onClick={() => {
                                                                                                setCouponVisible(true);
                                                                                            }}
                                                                                        >
                                                                                            Have a coupon?
                                                                                        </Button>)
                                                    }
                                                    
                                                </td>
                                                <td className="nw-black">Grand Total</td>
                                                <td className="nw-black text-right">
                                                    <span>$</span>
                                                    {orderDetail?.grandTotal?.toFixed(2)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                   
                                </div>
                                <div className="cst-img-container" style={{ marginTop: "77px" }}>
                                    <h2 className="fs-nunito-medium">
                                        Please Insert/Tap/Swipe Your Credit/Debit Card Now
                                    </h2>
                                    <div className="row cst-img-row" style={{ marginTop: "30px" }}>
                                        <img
                                            src="/assets/images/CardReader-min.gif"
                                            className="img-fluid"
                                            alt=""
                                            style={{
                                                width: "400px",
                                                height: "400px",
                                                borderRadius: "20px",
                                                padding: 0,
                                                margin: "0 auto",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end main content container  */}

            {/*start Coupon modal*/}
            <Modal visible={couponVisible}>
                <div className="modal-dialog modal-md modal-dialog-centered" style={{maxWidth:"600px"}}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <h2 className="text-uppercase">Apply coupon</h2>
                            <div className="col-lg-12 mt-3 position-relative">
                                {
                                    showLoading && <span className="cst-icon cst-rpickup-icon" style={{cursor: "pointer",right:"5px",top:"30px"}}>
                                                        <Loading visible={true} />
                                                    </span>
                                }
                            
                                <input
                                    className={`form-control`}
                                    style={{ paddingTop: '10px', paddingBottom: '10px',textAlign:'center', fontSize:'25px',borderRadius:'10px' }}
                                    name="coupon"
                                    id = "coupon"
                                    autoComplete="off"
                                    placeholder="Coupon Code"
                                    maxLength={maxLength}
                                    value={rentCouponCommand.coupon}
                                    onClick={(e: any) => {
                                        setTimeout(() => {
                                            e.target.focus();
                                        }, 100);
                                    }}
                                    onChange={(e: any) => {
                                        onCouponChange(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        e.target.focus();
                                        const pos = keyboard.current?.getCaretPosition();
                                        e.target.setSelectionRange(pos, pos);
                                    }}
                                />
                                {
                                    rentCouponCommand.couponError && <div style={{color:"#ca5b45"}} className="ms-1 mt-1">{rentCouponCommand.couponError}</div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer text-center">
                            <div className="d-flex justify-content-end cst-radious m-0">
                                
                                <Button
                                    type="button"
                                    //disabled={showLoading}
                                    className="btn fs-nunito-medium text-uppercase btn-gray"
                                    style={{borderBottomLeftRadius:".75em"}}
                                    onClick={() => {
                                        cancelCoupon();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="button"
                                    //disabled={showLoading}
                                    className="btn fs-nunito-medium text-uppercase"
                                    onClick={() => addCoupon()}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                layout="default"
                visible={couponVisible}
                onChange={(input: string) => {
                    onCouponChange(input);
                }}
                onOk={() => {
                    //setCouponVisible(false);
                }}
            />
             {/*end Coupon modal */}
        </>
    );
};

export default Payment;
